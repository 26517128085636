<template>
  <div class="content">
    <div class="search">
      <span class="nickname">商品名称：</span>
      <el-input v-model="input" placeholder="请输入内容"></el-input>
      <el-button size="small">搜索</el-button>
    </div>

    <el-card class="box-card" v-for="item in hotelList" :key="item.hotel_id">
      <div class="hotel_card" @click="HotelComment(item)">
        <div class="hotel_name">{{ item.name }}</div>
        <div class="grade">
          <div style="float:left"><span style="font-size:30px;color: #0486FE;">{{ item.rate }}</span>
            <span style="color:#0486FE">/5.0</span>
          </div>

          <span style="float:right;;">
            <div style=" text-align: center;color:red">12</div> 个待点评
          </span>
        </div>
      </div>
    </el-card>
    <div class="page">
      <el-pagination v-if="total_quantity" layout="total, sizes, prev, pager, next, jumper" :total="total_quantity"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page"></el-pagination>
    </div>
  </div>
</template>

<script>
import { getHotelList } from '@/api/comment'
import { aesKey } from '@/common/aesKey'
export default {
  data() {
    return {
      input: '',
      aesKey: null,
      hotelList: null,
      page: null,
      total_quantity: null,
      currentPage: 1,
    }
  },
  created() {
    this.getHotelList()
  },
  methods: {
    getHotelList() {
      let data = {
        type: 2
      }
      this.aesKey = aesKey(data)
      getHotelList(this.aesKey).then(res => {
        this.hotelList = res.data.data.list
        this.total_quantity = res.data.data.total
        this.page = res.data.data.page

      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let data = {
        page: val
      }
      this.aesKey = aesKey(data)
      getHotelComment(this.aesKey).then(res => {
        this.hotelList = res.data.data.list
        this.total_quantity = res.data.data.total
        this.page = res.data.data.page
      })
      console.log(`当前页: ${val}`);
    },
    HotelComment(item) {
      sessionStorage.setItem('hotelDetails', JSON.stringify(item))
      let data = JSON.stringify(item)
      this.$router.push({ path: `/comment/Comment_detail?hotelData=${data}` })
      console.log(item);
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  .box-card {
    display: inline-block;
    margin-right: 20px;
  }

  .search {
    margin-top: 20px;
    width: 100%;
    height: 50px;

    // background-color: aquamarine;
    .nickname {
      line-height: 50px;
    }

    display: flex;

    .el-input {
      width: 320px;
      line-height: 50px;
    }

    .el-button {
      width: 55px;
      margin-top: 10px;
      margin-left: 10px;
      height: 32px;
      background: #0486fe;
      border: #0486fe;
      color: white;
    }
  }

  .el-card {
    width: 278px;
    height: 158px;
    margin-top: 32px;
    border-radius: 10px;

    .el-card__body {
      overflow: hidden;
    }
  }

  .hotel_card {
    .hotel_name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 设置显示几行
      -webkit-box-orient: vertical;
    }

    .grade {
      overflow: hidden;
      margin-top: 42px;
    }
  }

  .page {
    position: absolute;
    bottom: 5%;
    right: 5%;
  }
}
</style>