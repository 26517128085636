import { request } from './request'
import qs from 'qs'
// /------------------------管理评论-------------------/
export function getHotelList(params){
return request({
  url: '/agent/hotel/list',
  method: 'POST',
  params
})
}
// export function GetHotelDetails(params){
//   return request({
//     url: '/agent/hotel/details',
//     method: 'POST',
//     params
//   })
//   }

export function GetHotelDetails(params){
    return request({
      url: '/admin/user/comment',
      method: 'GET',
      params
    })
    }